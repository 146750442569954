import * as React from "react"
import Navbar from "../components/Navbar"
import ErrorPages from "../components/error-pages/ErrorPages"
import "../components/error-pages/_error-page.scss"
import Seo from '../components/seo'

export default function NotFoundPage() {
  let props = {
    image1: "/images/error-pages/error1.svg",
    image1_mobile: "/images/error-pages/error1-mobile.svg",
    title1: (
      <>
        sorry! looks like we sent you <span> the wrong way.</span>
      </>
    ),
    title1_1: "let us guide you back",

    image2: "/images/error-pages/error2.svg",
    image2_mobile: "/images/error-pages/error2.svg",
    title2: (
      <>
        sorry! looks like we <span> lost our connection.</span>"
      </>
    ),
    title2_1: "but don’t worry, while we fix things",

    image3: "/images/error-pages/error3.svg",
    image3_mobile: "/images/error-pages/error3.svg",
    title3: (
      <>
        oops! the link you followed is probably <span> broken. </span>
      </>
    ),
    title3_1: "but don’t worry, while we fix things",

    image4: "/images/error-pages/error4.svg",
    image4_mobile: "/images/error-pages/error4.svg",
    title4: (
      <>
        sorry! looks like we sent you <span> the wrong way.</span>
      </>
    ),
    title4_1: "let us guide you back",

    image5: "/images/error-pages/error5.svg",
    image5_mobile: "/images/error-pages/error5.svg",
    title5: (
      <>
        sorry! looks like something <span>went wrong.</span>
      </>
    ),
    title5_1: "the link you followed is probably broken",

    image6: "/images/error-pages/error6.svg",
    image6_mobile: "/images/error-pages/error6.svg",
    title6: (
      <>
        oops! the link you followed is probably <span>broken.</span>
      </>
    ),
    title6_1: "but don’t worry, while we fix things",

    image7: "/images/error-pages/error7.svg",
    image7_mobile: "/images/error-pages/error7.svg",
    title7: (
      <>
        orry! looks like we sent you <span> the wrong way.</span>
      </>
    ),
    title7_1: "let us guide you back",

    image8: "/images/error-pages/error8.svg",
    image8_mobile: "/images/error-pages/error8.svg",
    title8: (
      <>
        oops! the link you followed is probably <span>broken.</span>
      </>
    ),
    title8_1: "but don’t worry, while we fix things",

    image9: "/images/error-pages/error9.svg",
    image9_mobile: "/images/error-pages/error9.svg",
    title9: (
      <>
        sorry! looks like we sent you <span> the wrong way.</span>
      </>
    ),
    title9_1: "let us guide you back",
  }
let seo={
  lang: `en`,
  meta: [],
  description: `Page Not Found`,
  data:{
    metaDesc:"Page Not Found",
    title:"Page Not Found"
  }
};
  return (
    <>
     <Seo {...seo}/>
      <Navbar />

      <ErrorPages {...props} />
    </>
  )
}
