import React, { useContext } from "react"
import "./_error-page.scss"
import { string_translation, createMarkup } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {Link, useStaticQuery, graphql} from "gatsby"

function ErrorPages(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  
  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      goBack_ar:translateString(language: AR, string: "CLICK HERE TO GO BACK")
      goBack_en:translateString(language: EN, string: "CLICK HERE TO GO BACK")

      home_ar:translateString(language: AR, string: "HOMEPAGE NAME")
      home_en:translateString(language: EN, string: "HOMEPAGE NAME")

      title1_ar:translateString(language: AR, string: "404 LINE 1")
      title1_en:translateString(language: EN, string: "404 LINE 1")

      title2_ar:translateString(language: AR, string: "404 LINE 2")
      title2_en:translateString(language: EN, string: "404 LINE 2")
    }
  }`)


  return (
    <>
      <section className="error-prompt">
        <div className="container">
          <div className="error-wrapper">
            <div className="img-wrapper desktop-show">
              <img src={props.image7} alt="Error" />
            </div>
            <div className="img-wrapper mobile-show">
              <img src={props.image1_mobile} alt="Error" />
            </div>
            <div className="big-text" dangerouslySetInnerHTML={createMarkup(
                      string_translation(stringTranslations,"title1", locale)
                    )}>
            
            </div>
            <br />
            <div className="big-text">{string_translation(stringTranslations,"title2", locale)}</div>
            <div className="back-home">
            {string_translation(stringTranslations,"goBack", locale)}{" "}
              <Link to={"/"+locale} className="click-home">
                {" "}
                {string_translation(stringTranslations,"home", locale)}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default ErrorPages
